import { forwardRef, useState } from 'react'
import {
  Box,
  FormControl,
  RadioGroup,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  Theme,
} from '@mui/material'
import { Button } from 'components'
import { FormEventHandler } from 'react'
import axios from 'axios'

const ContactForm = forwardRef<HTMLDivElement>((_, ref) => {
  const [hasFormSent, setHasFormSent] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    preferredContactMethod: 'email',
    message: '',
    'form-name': 'contact',
  })

  const updateFormData = (updatedFormData: object) => {
    setFormData({ ...formData, ...updatedFormData })
  }

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    axios
      .request({
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: '/',
        data: Object.keys(formData)
          .map(
            (key) =>
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(formData[key as keyof typeof formData]),
          )
          .join('&'),
      })
      .then(() => {
        setHasFormSent(true)
      })
      .catch((error) => console.error(error))
  }

  return hasFormSent ? (
    <Box
      component="form"
      sx={{
        width: '100%',
        maxWidth: '50rem',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        minHeight: '15rem',
        textAlign: 'center',
        mt: 6,
      }}
      onSubmit={onSubmit}
    >
      <Typography variant="body1">Your message has been sent</Typography>
      <Typography variant="body1">
        Thank you for taking the time to reach out
      </Typography>
      <Typography variant="body1">
        I will contact you as soon as possible
      </Typography>
      <Button
        disabled
        label="Send message"
        sx={{ alignSelf: 'center', mt: 6, width: '15rem' }}
      />
    </Box>
  ) : (
    <Box
      ref={ref}
      component="form"
      name="contact"
      sx={{
        width: '100%',
        maxWidth: '50rem',
        display: 'flex',
        flexDirection: 'column',
        mt: 4,
        rowGap: 2,
      }}
      onSubmit={onSubmit}
    >
      <TextField
        label="Your Name"
        required
        value={formData.name}
        onChange={(e) => {
          updateFormData({ name: e.target.value })
        }}
      />
      <TextField
        label="Email Address"
        required={formData.preferredContactMethod === 'email'}
        value={formData.email}
        onChange={(e) => {
          updateFormData({ email: e.target.value })
        }}
        type="email"
      />
      <TextField
        label="Phone Number"
        required={formData.preferredContactMethod === 'phone'}
        value={formData.phone}
        onChange={(e) => {
          updateFormData({ phone: e.target.value })
        }}
      />
      <FormControl>
        <FormLabel>Preferred Contact Method</FormLabel>
        <RadioGroup
          row
          aria-labelledby="preferred-contact-label"
          name="preferred-contact-method"
          value={formData.preferredContactMethod}
          onChange={(e) => {
            updateFormData({ preferredContactMethod: e.target.value })
          }}
        >
          <FormControlLabel value="email" control={<Radio />} label="Email" />
          <FormControlLabel value="phone" control={<Radio />} label="Phone" />
        </RadioGroup>
      </FormControl>

      <TextField
        label="Message"
        required
        value={formData.message}
        multiline
        rows={5}
        onChange={(e) => {
          updateFormData({ message: e.target.value })
        }}
      />
      <Button
        type="submit"
        label="Send message"
        sx={{ alignSelf: 'center', mt: 2, width: '15rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          columnGap: '0.5rem',
          mt: 1,
        }}
      >
        <Typography variant="body1">
          Call{' '}
          <Box
            component="a"
            href="tel:14164583515"
            sx={{
              colour: (theme: Theme) => theme.palette.primary.main,
              ':-webkit-any-link': {
                color: (theme: Theme) => theme.palette.primary.main,
              },
            }}
          >
            (416) 458 3515
          </Box>
        </Typography>
        <Typography variant="body1">
          or email{' '}
          <Box
            component="a"
            href="mailto:bruce@brucegarrod.ca"
            sx={{
              colour: (theme: Theme) => theme.palette.primary.main,
              ':-webkit-any-link': {
                color: (theme: Theme) => theme.palette.primary.main,
              },
            }}
          >
            Bruce@BruceGarrod.ca
          </Box>
        </Typography>
      </Box>
    </Box>
  )
})

export default ContactForm
