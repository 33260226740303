import {
  Box,
  List,
  Theme,
  Drawer,
  Divider,
  ListItem,
  ListItemText,
  useMediaQuery,
  ListItemButton,
} from '@mui/material'
import { Button } from 'components'
import { useNavigate } from 'react-router-dom'

interface MainDrawerProps {
  show: boolean
  setShow: Function
  topMenuBarHeight?: number
  setShowSignModal: (arg0: boolean) => void
  setShowDonateModal: (arg0: boolean) => void
}

const nonMobileDrawerWidth = 290

const MenuDrawer = ({
  show,
  setShow,
  topMenuBarHeight,
  setShowSignModal,
  setShowDonateModal,
}: MainDrawerProps) => {
  const navigate = useNavigate()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  return (
    <Drawer
      open={show && isTablet}
      anchor="right"
      sx={{
        width: isMobile ? '100%' : nonMobileDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : nonMobileDrawerWidth,
          boxSizing: 'border-box',
        },
      }}
      onBackdropClick={() => {
        setShow(false)
      }}
    >
      <Box
        sx={{
          mt: `${topMenuBarHeight}px`,
        }}
      >
        <List>
          <ListItem disablePadding key="home">
            <ListItemButton
              onClick={() => {
                setShow(false)
                navigate('/')
              }}
            >
              <ListItemText primary={'Home'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding key="What Bruce Will Do">
            <ListItemButton
              onClick={() => {
                setShow(false)
                navigate('/what-bruce-will-do')
              }}
            >
              <ListItemText primary={'What Bruce Will Do'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding key="What Bruce Has Done">
            <ListItemButton
              onClick={() => {
                setShow(false)
                navigate('/what-bruce-has-done')
              }}
            >
              <ListItemText primary={'What Bruce Has Done'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding key="In the news">
            <ListItemButton
              onClick={() => {
                setShow(false)
                navigate('/in-the-news')
              }}
            >
              <ListItemText primary={'In the news'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding key="Contact">
            <ListItemButton
              onClick={() => {
                setShow(false)
                navigate('/#contact')
              }}
            >
              <ListItemText primary={'Contact'} />
            </ListItemButton>
          </ListItem>

          <Divider />

          {isMobile && [
            <ListItem
              disablePadding
              sx={{ my: 2, px: 2 }}
              key="Get a lawn sign"
            >
              <Button
                fullWidth
                label="Get a lawn sign"
                variant="contained"
                colour="yellow"
                onClick={() => {
                  setShow(false)
                  setShowSignModal(true)
                }}
              />
            </ListItem>,
            <ListItem disablePadding sx={{ px: 2 }} key="Donate">
              <Button
                fullWidth
                label="Donate"
                variant="outlined"
                colour="blue"
                onClick={() => {
                  setShow(false)
                  setShowDonateModal(true)
                }}
              />
            </ListItem>,
          ]}
        </List>
      </Box>
    </Drawer>
  )
}

export default MenuDrawer
