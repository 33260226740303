import {
  Box,
  Theme,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import { Button } from 'components'

interface MenuBarProps {
  isMenuDrawerOpen: boolean
  setIsMenuDrawerOpen: Function
  setShowSignModal: (arg0: boolean) => void
  setShowDonateModal: (arg0: boolean) => void
}

const MenuBar = forwardRef<HTMLDivElement, MenuBarProps>(
  (
    {
      isMenuDrawerOpen,
      setIsMenuDrawerOpen,
      setShowSignModal,
      setShowDonateModal,
    }: MenuBarProps,
    ref,
  ) => {
    const navigate = useNavigate()

    const isTablet = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('lg'),
    )
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm'),
    )

    return (
      <AppBar
        ref={ref}
        position="fixed"
        sx={{
          backgroundColor: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              navigate('/')
            }}
          >
            Bruce Garrod for Regional Councillor
          </Typography>
          <Box
            sx={{
              display: isTablet ? 'none' : 'flex',
              mr: 2,
              columnGap: 1,
              pt: 0.5,
            }}
          >
            <Button
              label="What Bruce Will Do"
              variant="text"
              onClick={() => {
                navigate('/what-bruce-will-do')
              }}
            />
            <Button
              label="What Bruce Has Done"
              variant="text"
              onClick={() => {
                navigate('/what-bruce-has-done')
              }}
            />
            <Button
              label="In the News"
              variant="text"
              onClick={() => {
                navigate('/in-the-news')
              }}
            />
            <Button
              label="Contact"
              variant="text"
              onClick={() => {
                navigate('/#contact')
              }}
            />
          </Box>
          <Box
            sx={{
              display: isMobile ? 'none' : 'flex',
              columnGap: 2,
            }}
          >
            <Button
              label="Get a lawn sign"
              variant="contained"
              colour="yellow"
              onClick={() => {
                setShowSignModal(true)
              }}
            />
            <Button
              label="Donate"
              variant="outlined"
              colour="blue"
              onClick={() => {
                setShowDonateModal(true)
              }}
            />
          </Box>

          {isTablet && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 2 }}
              onClick={() => {
                setIsMenuDrawerOpen(!isMenuDrawerOpen)
              }}
            >
              {isMenuDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    )
  },
)

export default MenuBar
