import { useEffect, useRef } from 'react'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Paper,
  Typography,
  Theme,
  useMediaQuery,
  alpha,
} from '@mui/material'

import { Button } from 'components'
import ContactForm from './ContactForm'

import bruceImage from 'assets/images/bruce-garrod-museum-lands.jpg'
import downtownImage from 'assets/images/bruce-garrod-downtown-uxbridge.jpg'
import hospitalImage from 'assets/images/bruce-garrod-hospital-long-term-care.jpg'

const topParagraphCopy =
  "It's time to bring a passionate and determined voice to the role of Uxbridge's Durham Region Councillor — someone who will bring attention and action to issues that matter to the community."
const topParagraphCta = 'Choose Bruce Garrod to be that voice.'

const HomeView = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const contactFormRef = useRef<HTMLDivElement>(null)

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  )

  useEffect(() => {
    if (location.hash === '#contact' && contactFormRef.current) {
      window.history.pushState('', '', '/')
      window.scrollTo({
        top: contactFormRef.current.getBoundingClientRect().top - 150,
        behavior: 'smooth',
      })
    }
  }, [location])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isSmallScreen && (
        <Box
          sx={{
            width: '100%',
            height: '40vw',
            maxHeight: '30rem',
            backgroundImage: `url(${bruceImage})`,
            backgroundSize: 'cover',
            backgroundPosition: '50% 20%',
            position: 'relative',
          }}
        >
          <Paper
            elevation={0}
            sx={{
              position: 'absolute',
              right: '2rem',
              top: '7rem',
              backgroundColor: alpha('#FFFFFF', 0.9),
              width: '40vw',
              p: 4,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: 'left',
                lineHeight: 2.2,
                fontSize: '1.2rem',
              }}
            >
              {topParagraphCopy}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                textAlign: 'left',
                lineHeight: 2.2,
                mt: 2,
              }}
            >
              {topParagraphCta}
            </Typography>
          </Paper>
        </Box>
      )}

      {isSmallScreen && (
        <>
          {/* Top Image */}
          <Box
            component="img"
            src={bruceImage}
            sx={{
              width: '100%',
              height: '40vw',
              maxHeight: '30rem',
              objectFit: 'cover',
              objectPosition: '50% 20%',
            }}
          />

          {/* Top Paragraph */}
          <Box
            sx={{
              display: 'flex',
              py: isTablet ? 4 : 8,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: isTablet ? 2 : 4,
                mx: 4,
                width: isTablet ? '100%' : '50%',
                background: (theme: Theme) => theme.palette.primary.main,
                color: '#FFFFFF',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  lineHeight: 2.2,
                }}
              >
                {topParagraphCopy}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center',
                  lineHeight: 2.2,
                  mt: 2,
                }}
              >
                {topParagraphCta}
              </Typography>
            </Paper>
          </Box>
        </>
      )}

      {/* Who is Bruce Garrod */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 4,
          py: isTablet ? 4 : 8,
          justifyContent: 'center',
          textAlign: 'center',
          columnGap: 8,
          rowGap: 4,
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          color: '#FFFFFF',
        }}
      >
        <Typography variant="h5">Who is Bruce Garrod?</Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            lineHeight: 1.8,
            maxWidth: '40rem',
            mx: 'auto',
          }}
        >
          Since moving to Uxbridge with his wife Robin in 1999 to raise their
          two children, Bruce Garrod has been involved in the community in a
          variety of ways, ranging from sponsoring and coaching endless sports
          teams to leading an advocacy committee to bring a new Long-Term Care
          home to Uxbridge.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            lineHeight: 1.8,
            maxWidth: '40rem',
            mx: 'auto',
          }}
        >
          Following a 20 year corporate career, Bruce established a project
          management consulting firm where he delivered projects and taught
          project management skills around the world. In 2018, Bruce became the
          proud Councillor of Uxbridge Ward 3 and has been the Chair of Finance
          for 4 years.
        </Typography>
      </Box>

      {/* What I'll Do */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isTablet ? 'column' : 'row',
          px: 4,
          py: isTablet ? 4 : 8,
          justifyContent: 'center',
          columnGap: 8,
          rowGap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            width: isTablet ? '100%' : '50%',
            justifyContent: 'center',
            alignItems: isTablet ? 'center' : undefined,
            textAlign: isTablet ? 'center' : undefined,
          }}
        >
          <Typography variant="h5">Bruce's Pledge to You</Typography>
          <Typography variant="body1">
            Durham Region is experiencing a period of phenomenal growth — the
            population is expected to double within 30 years and Uxbridge will
            be part of the rise in population. A realistic plan must be built
            that will allow controlled growth in Uxbridge while improving the
            services provided by Durham Region (transit, infrastructure, and
            social services).
          </Typography>
          <Typography variant="body1">
            With 57% of property tax bills being directed to Durham Region,
            Uxbridge deserves and will need more support.
          </Typography>
          <Button
            label="Learn more"
            variant="contained"
            colour="yellow"
            endIcon={<ReadMoreIcon />}
            onClick={() => {
              navigate('/what-bruce-will-do')
            }}
            sx={{
              width: '10rem',
            }}
          />
        </Box>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            rowGap: '2',
            width: isTablet ? '100%' : '50%',
          }}
        >
          <Box
            component="img"
            src={downtownImage}
            sx={{
              width: '100%',
              height: '40vw',
              maxHeight: '30rem',
              objectFit: 'cover',
              objectPosition: '50% 45%',
            }}
          />
        </Paper>
      </Box>

      {/* What I've Done */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isTablet ? 'column' : 'row-reverse',
          px: 4,
          py: isTablet ? 4 : 8,
          justifyContent: 'center',
          columnGap: 8,
          rowGap: 4,
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            width: isTablet ? '100%' : '50%',
            justifyContent: 'center',
            alignItems: isTablet ? 'center' : 'flex-end',
            textAlign: isTablet ? 'center' : undefined,
            color: '#FFFFFF',
          }}
        >
          <Typography variant="h5">Keeping Promises</Typography>
          <Typography variant="body1">
            During the 2018 campaign, Bruce's primary promises were to act on
            road safety, improve service to Uxbridge's seniors and advocate to
            bring a new Long-Term Care home to Uxbridge. All have been
            fulfilled.
          </Typography>
          <Button
            label="Learn more"
            variant="contained"
            colour="yellow"
            endIcon={<ReadMoreIcon />}
            onClick={() => {
              navigate('/what-bruce-has-done')
            }}
            sx={{
              width: '10rem',
            }}
          />
        </Box>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            rowGap: '2',
            width: isTablet ? '100%' : '50%',
          }}
        >
          <Box
            component="img"
            src={hospitalImage}
            sx={{
              width: '100%',
              height: '40vw',
              maxHeight: '30rem',
              objectFit: 'cover',
              objectPosition: '50% 45%',
            }}
          />
        </Paper>
      </Box>

      {/* Contact form */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 4,
          py: isTablet ? 4 : 8,
        }}
      >
        <Typography variant="h5">Contact Me</Typography>
        <ContactForm ref={contactFormRef} />
      </Box>
    </Box>
  )
}

export default HomeView
